<template>
  <div class="w-100 h-100">
    <b-card class="w-100 h-100" v-if="Watchlist">
      <table class="table table-borderless">
        <thead class="thead-success">
          <tr class="font-weight-bolder-header">
            <th scope="col" class="text-left">Name</th>
            <th scope="col" class="text-left">Symbol</th>
            <th scope="col" class="text-left">% Change</th>
            <th scope="col" class="text-left">Price</th>
            <th scope="col" class="text-left">Marketcap</th>
            <th scope="col" class="text-left"></th>
            <th scope="col" class="text-left"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item) in companyTable" :key="item.id" class="data-row">
            <td class="text-left power-effect" @click="handleCompanyClick(item.Name)">
              <span class="data-label">Name: </span>{{ item.Name }}
            </td>
            <td class="text-left">
              <span class="data-label">Symbol: </span>{{ item.Ticker }}
            </td>
            <td class="text-left">
              <span class="data-label">% Change: </span>
              <b-badge :variant="variant(item.Change)">
                {{ Math.round(item.Change) + "%" }}
              </b-badge>
            </td>
            <td class="text-left">
              <span class="data-label">Price: </span>{{ "$" + nFormatter(item.Price) }}
            </td>
            <td class="text-left">
              <span class="data-label">Marketcap: </span>{{ nFormatter(item.MarketCap) }}
            </td>
            <td class="text-left button-cell">
              <b-button class="align-middle action-button" variant="danger" @click="confirmText(item.Name)">
                <feather-icon icon="Trash2Icon" class="align-middle" />
              </b-button>
              <b-button class="align-middle action-button" variant="success" @click="addToPortfolio(item.Name), PortfolioToast(item.Name)" v-b-popover.hover="'By clicking this Button you can add this specific company directly to your Portfolio'">
                <feather-icon icon="PlusIcon" class="mr-40" />
                <span class="align-middle">Portfolio</span>
              </b-button>
            </td>
          </tr>
        </tbody>
      </table>
    </b-card>
    <b-modal
      class="rounded-header"
      id="modal-login"
      cancel-variant="outline-danger"
      ok-variant="info"
      v-model="showLoginModal"
      ok-title="Login"
      cancel-title="Close"
      centered
      title="You need to Be Signed up for access"
      size="md"
      @ok="firelogin()"
      @cancel="handleCancel()"
      @hide="handleCancel()"
    >
      <b-form>
        <b-form-group class="rounded-header" >
          <b-form-input
            id="email"
            type="email"
            placeholder="Email Address"
            class="rounded-input"
            v-model="userEmail"
          />
        </b-form-group>
        <b-form-group class="rounded-header" >
          <b-form-input
            id="password"
            type="password"
            placeholder="Password"
            class="rounded-input"
            v-model="password"
          />
        </b-form-group>
        <div class="text-center">
        <hr>
        <span class="">OR</span>
        <hr >
      </div>

        <button type="button" class="google-sign-in-button btn btn-outline-secondary text-center btn-block" @click="GoogleSignIn">
              Sign In with Google
            </button>
      </b-form>
    </b-modal>
  </div>
  
</template>

<script>
import {
  BCard, BTable, BAvatar, BImg, BBadge,BButton
} from 'bootstrap-vue'
import { doc, snapshot, getDoc, documentId,query,collection,arrayUnion, where, getDocs,updateDoc, arrayRemove, FieldValue,deleteField,setDoc } from "firebase/firestore";
import { getAuth,onAuthStateChanged  } from "firebase/auth";
import { db } from '@/firebase/index'
import { EventBus } from '@/main'
import {VBPopover, BPopover ,BModal, VBModal,BForm, BFormInput, BFormGroup, } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {  signInWithEmailAndPassword,GoogleAuthProvider, signInWithPopup  } from "firebase/auth";
import { Ref } from 'vue-demi'






export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BButton,
    VBPopover, 
    BPopover,
    BModal, 
    VBModal,
    BForm, 
    BFormInput, 
    BFormGroup,
    
    
  },
  computed: {
    Watchlist() {
      return this.$store.getters.getWatchlist
    },
    companyTable() { 
       return this.companyTable = this.Watchlist
    },
    SweetAlert() {
      if (this.Watchlist == []) {
        return this.EmptyWatchlist(Name)
      } 
    },
    

  },
  directives: {
    'b-modal': VBModal,
    Ripple,

  },
   created() {
    this.getWatchlist()
  },
  data() {
    return {  
      showLoginModal: false,
      password: '',
      userEmail: '',
    }
  },
  methods: {
    firelogin() {
      console.log('FIIIIIIIIIIRE')
      const auth = getAuth();
      signInWithEmailAndPassword(auth, this.userEmail, this.password)
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          console.log(user.uid)
          const uid = user.uid;
          const docRef = doc(db, "users", uid);
          const docSnap = await getDoc(docRef);
          const userData = docSnap.data().userData
          localStorage.setItem('userData', JSON.stringify(userData))
          this.$ability.update(userData.ability)
          this.$router.push('/companyanalysis')
        } else {
          console.log('Error')
        }
      
        })
    },
    async GoogleSignIn() {
      try {
        const auth = getAuth();
        const provider = new GoogleAuthProvider();
        
        // Sign in with Google using a popup
        const result = await signInWithPopup(auth, provider);
        
        // The signed-in user info
        const user = result.user;
        
        // Now you can do whatever you want with the user object, such as storing it in Vuex or Firebase Firestore
        console.log('User signed in with Google:', user);
        if (user) {
          const uid = user.uid;
          const docRef = doc(db, "users", uid);
          const docSnap = await getDoc(docRef);
          const userData = docSnap.data().userData
          localStorage.setItem('userData', JSON.stringify(userData))
          
          console.log(userData)
          this.$ability.update(userData.ability)
        }
        
        
        // Optionally, you can redirect the user to another page or perform other actions here
      } catch (error) {
        // Handle errors here
        console.error('Error signing in with Google:', error);
        // Optionally, you can show an error message to the user
      }
    },
    handleCancel() {
    this.$router.push('/companyanalysis'); // Ändere '/desired-page' auf die gewünschte Zielseite
  },

    async handleCompanyClick(compName) {
      const docSnap = await getDoc(doc(db, 'companies', compName))

      if (docSnap.exists()) {
        // assign document fields
        // to data properties
        const data = docSnap.data()
        EventBus.$emit('getCompany',data)
        this.$store.dispatch('setCurrentCompdata',data)
        this.$router.push('/companyanalysis')
      } else {
        console.log('Document does not exist')
      }
    },
    nFormatter(num) {
     if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
     }
     if (num >= 1000000) {
        return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
     }
     if (num >= 1000) {
        return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
     }
     return num;
    },
  
    async getWatchlist() {
      const table = []
      const auth = getAuth();
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          const uid = user.uid;
          const docRef = doc(db, "users", uid);
          const docSnap = await getDoc(docRef);
          const bata = docSnap.data().Watchlist
          if (docSnap.data().Watchlist !== undefined) {
            const array = Object.keys(bata)
            const ocRef= collection(db, "companies")
            const q = query(ocRef, where(documentId(), 'in', array));
            const querySnapshot = await getDocs(q)
            querySnapshot.forEach((doc) => {
              // doc.data() is never undefined for query doc snapshots
              const Ticker = doc.data().Ticker
              const MarketCap = doc.data().MarketCap
              const BuyPrice = bata[doc.id]
              const change = ((doc.data().LastPrice - BuyPrice)/BuyPrice)*100
              const data = {"Name":doc.id, "Ticker": Ticker ,  "MarketCap": MarketCap, "Price": doc.data().LastPrice, "Change": change}
              table.push(data)
            });
            this.$store.dispatch('setWatchlist',table)
          }  else {
            this.EmptyWatchlist('TEST123')
          }
        } else {
          this.showLoginModal = true;
        }
      });
    },
    async RemoveCompany(Name) {
      const auth = getAuth();
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          const uid = user.uid;
          // await updateDoc(doc(db, "users", uid), {Watchlist: FieldValue.delete(Name)} );
          await updateDoc(doc(db, "users", uid), {[`Watchlist.${Name}`]: deleteField()});
          this.getWatchlist()
        }})
    },
    PortfolioToast(Name) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Portfolio',
          icon:"PieChartIcon",
          text: `${Name} was added`,
          variant:"success",
        },
      })
    },
    variant: function (value) {
      if(value < 0) {
        return  "light-danger"
      }
      if(value > 0) {
        return  "light-success"
      }
    },
    confirmText(Name) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.RemoveCompany(Name)
          this.$swal({
            icon: 'success',
            title: 'Deleted!',
            text: `${Name} successfully deleted`,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    EmptyWatchlist() {
      this.$swal({
        title: 'Nothing here ?,',
        text: 'Add some companies in the screener or Company Analysis section and come back :)',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.showLoginModal = true;
        }
      })
    },
    NotSignedIn() {
      this.$swal({
        title: 'Registered Account needed,',
        text: 'you need to be atleast have the free Tier to unlock this feature. Sign up Now to Save Companies',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.showLoginModal = true;
        }
      })
    },
    async addToPortfolio(x)  {
      const auth = getAuth();
      const user = auth.currentUser.uid;
      const docSnap = await getDoc(doc(db, 'companies', x))
        if (docSnap.exists()) {
          // assign document fields
          // to data properties
          const data = docSnap.data()
          const Price = data.LastPrice
          await updateDoc(doc(db,"users",user),({ Portfolio: arrayUnion({Name: x, Quantity:1, BuyPrice: Price})}),{ merge: true })
        }

    },
  }
  
}
</script>

<style lang="scss" scoped>
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';
.google-sign-in-button {
  cursor: pointer;
  transition: background-color .3s, box-shadow .3s;
  padding: 20px 16px; /* Adjusted padding */
  border: none;
  border-radius: 10px;
  color: #FFFFFF;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-repeat: no-repeat;
  background-position: 90px 50%; /* Adjusted background position */

}

.google-sign-in-button:hover {
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
}

.google-sign-in-button:active {
  background-color: #eeeeee;
  outline: none;
  box-shadow: 
    0 -1px 0 rgba(0, 0, 0, .04),
    0 2px 4px rgba(0, 0, 0, .25),
    0 0 0 3px #c8dafc;
}


.b-button-google:hover {
  border-color: #2d3748;
  color: #1a202c;
  background-color: #edf2f7;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.rounded-header {
  border-radius: 15px;
}


.rounded-input {
  border-radius: 10px;
}


.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
.power-effect {
  position: relative;
  text-align: center;
  font-size: 15px;
  padding: 20px;
  color: primary;
  
  transition: transform 0.2s, box-shadow 0.2s;
}

.power-effect:hover {
  transform: scale(1.05);
 
}
@import '@core/scss/vue/pages/page-auth.scss';

.table {
  width: 100%;
  border-collapse: collapse;
}

.thead-success th, .data-row td {
  padding: 8px; /* Uniform padding for headers and cells */
  text-align: left; /* Ensure left alignment */
  vertical-align: middle; /* Align vertically to middle for better appearance */
}

.thead-success th {
  background-color: #d4edda;
  font-weight: bold;
}

.data-label {
  display: none;
  font-weight: bold;
}

.button-cell .action-button {
  margin-right: 10px; /* Add space between buttons */
}

.button-cell .action-button:last-child {
  margin-right: 0; /* Remove margin from the last button */
}

@media (max-width: 768px) {
  .thead-success {
    display: none;
  }

  .data-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .data-row td {
    display: flex;
    width: 100%;
    padding: 5px 0;
    border-bottom: 1px solid #ddd;
  }

  .data-label {
    display: inline-block;
    width: 30%;
    padding-right: 10px;
  }

  .data-row td:last-child {
    border-bottom: none;
  }

  .button-cell {
    display: flex;
    justify-content: flex-start; /* Align buttons to the start */
    width: 100%;
  }
}

</style>
