<template>
    <b-card class="full-width full-height">
        <b-card-title>
            My Watchlist
        </b-card-title>
        <b-card-sub-title class="mb-3">
            
            <!-- <b-button
                class="float-right"
                href="/screener"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"

>
                <feather-icon
                    icon="PlusIcon"
                    class="mr-30"
                />
                    Add Company
                </b-button> -->
        </b-card-sub-title>
        
        <div class="row mt-4">
            <Table/>
        </div>
    </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BCardTitle, BCardText, BButton
} from 'bootstrap-vue'
import Table from "./Table.vue"
import Ripple from 'vue-ripple-directive'
import { directive } from 'vue-awesome-swiper'
import { doc, getDoc,collection,query,where,documentId, getDocs } from "firebase/firestore";
import { getAuth,onAuthStateChanged  } from "firebase/auth";
import { db } from '@/firebase/index'



export default  {
    components: {
    BCard,
    BRow,
    BCol,
    BCardTitle,
    BCardText,
    BButton,
    Table,
    },
    directive:{
        Ripple,
    },
}
</script>


<style lang="scss">
    @import '@core/scss/vue/pages/dashboard-ecommerce.scss';
    @import '@core/scss/vue/libs/chart-apex.scss';
 </style>